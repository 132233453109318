import { Card, Button, Dropdown } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { US_STATES } from '../../../../constants'
import ImagePreview from '../../../../components/ImagePreview'
import ActionsMenu from './ActionsMenu'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advancedFormat)

const FeaturedProgramCard = ({ featuredProgram, onEdit, onDelete, isReordering }) => {
  const programImage = featuredProgram.programImages?.[0]
  const programLocation = featuredProgram.programLocations[0]

  return (
    <Card
      style={{
        width: '320px',
        position: 'relative',
      }}
      bodyStyle={{ padding: '12px' }}
    >
      {!isReordering && (
        <Dropdown
          overlay={
            <ActionsMenu featuredProgram={featuredProgram} onDelete={onDelete} onEdit={onEdit} />
          }
          trigger={['click']}
        >
          <Button
            type="text"
            icon={<EllipsisOutlined />}
            style={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              padding: '4px 8px',
              height: 'auto',
              zIndex: 1,
            }}
          />
        </Dropdown>
      )}
      <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
        <div style={{ width: '80px', flexShrink: 0 }}>
          {programImage ? (
            <ImagePreview
              asset={programImage.asset}
              width="80px"
              height="80px"
              style={{ objectFit: 'cover', borderRadius: '4px' }}
            />
          ) : (
            <div
              style={{
                width: '80px',
                height: '80px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#999',
              }}
            >
              No Image
            </div>
          )}
        </div>
        <div style={{ flex: 1, minWidth: 0 }}>
          <div
            style={{
              fontSize: '14px',
              fontWeight: 500,
              marginBottom: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: 'calc(100% - 20px)',
            }}
          >
            {featuredProgram.name}
          </div>
          <div style={{ color: '#666', fontSize: '14px' }}>
            <div style={{ fontSize: '12px' }}>
              {formatDateWithTimeSlot(
                dayjs.utc(featuredProgram.date).format('YYYY-MM-DD'),
                featuredProgram.startTime,
                featuredProgram.endTime,
                featuredProgram.tz,
              )}
            </div>
            {programLocation && (
              <div style={{ fontSize: '10px', marginTop: '2px' }}>
                <div>{programLocation.location.name}</div>
                <div>
                  {programLocation.location.city}, {programLocation.location.state}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export const formatDateWithTimeSlot = (date, startTime, endTime, timezone) => {
  const dateStartTime = dayjs(`${date}T${startTime}`).tz(timezone, true)
  const dateEndTime = dayjs(`${date}T${endTime}`).tz(timezone, true)

  return `${dateStartTime.format('MMMM DD, YYYY')}, ${dateStartTime.format(
    'HH:mm',
  )}–${dateEndTime.format('HH:mm')} ${dateStartTime.tz(timezone).format('z')}`
}

export default FeaturedProgramCard
