import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { actions as acts } from '../../../../constants'
import { useStatus, useStatusMsg } from '../../../../reducers'
import { clearStatus } from '../../../../actions/status'
import { updateProgram } from '../../../../actions/admin'
import FeaturedProgramForm from './FeaturedProgramForm'
import { ProgramSelect, ProgramSelectOption } from '../../../../components/program'

const FeaturedProgramModal = ({ complete, featuredProgram: initialFeaturedProgram }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}

  const [featuredProgram, setFeaturedProgram] = useState(initialFeaturedProgram)
  const status = {
    update: useStatus(acts.UPDATE_PROGRAM),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_PROGRAM))
    }
  }, [dispatch])

  useStatusMsg(status.update, { error: 'Failed to update featured program' })

  const onOk = async () => {
    const payload = {
      featuredWindow: featuredProgram.featuredWindow,
      featuredOnHomepage: featuredProgram.featuredOnHomepage,
    }

    dispatch(updateProgram(featuredProgram.id, payload))
      .then(res => completer(res))
      .catch(() => {})
  }

  const formComplete = !!featuredProgram

  const isEditing = !!initialFeaturedProgram

  return (
    <Modal
      title={isEditing ? 'Edit Featured Program' : 'Add Featured Program'}
      open={true}
      okText={isEditing ? 'Save' : 'Add'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.update.pending || false}
    >
      {featuredProgram ? (
        <>
          <div style={{ position: 'relative', marginBottom: '4px' }}>
            <ProgramSelectOption program={featuredProgram} />
            <Button
              variant="text"
              onClick={() => setFeaturedProgram(null)}
              style={{ position: 'absolute', right: 0, top: 0 }}
            >
              Change
            </Button>
          </div>
          <FeaturedProgramForm
            featuredProgram={featuredProgram}
            onChange={updatedFeaturedProgram => setFeaturedProgram(updatedFeaturedProgram)}
          />
        </>
      ) : (
        <ProgramSelect
          value={null}
          onChange={setFeaturedProgram}
          placeholder="Select a program"
          style={{ width: '100%' }}
          searchAcrossOrgs={true}
        />
      )}
    </Modal>
  )
}

export default FeaturedProgramModal
