import React, { useEffect, useReducer } from 'react'
import { DatePicker, Checkbox } from 'antd'
import { InputGroup, Input, Title, Desc } from './step'
import Attr from '../../../components/Attr'
import dayjs from 'dayjs'
import styled from 'styled-components'

const PositionInput = styled(Input)`
  .ant-input-wrapper.ant-input-group {
    width: ${props => props.theme.width[5]};
  }
`

const initState = ({ featuredWindow, featuredWindowPosition, featuredOnHomepage }) => ({
  featuredWindow,
  featuredWindowPosition,
  featuredOnHomepage: !!featuredOnHomepage,
})

const reducer = (state, action) => ({ ...state, ...action })

const Step = ({ onChange, clinic }) => {
  const [state, dispatch] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [state, onChange])

  return (
    <>
      <InputGroup>
        <Title>Featured Window</Title>
        <Desc>
          Provide a date range during which this program will be featured on the club it belongs to.
          In case the end date is left empty, the program will be featured til the program is not
          bookable anymore. On the other hand, if it doens't have a start date, the window will not
          work.
        </Desc>
        <Attr name="Start date">
          <DatePicker
            size="large"
            placeholder={`Start date`}
            disabledDate={current => current && current <= dayjs().startOf('day')}
            defaultValue={
              state.featuredWindow?.dateStart ? dayjs(state.featuredWindow.dateStart) : null
            }
            onChange={(date, dateString) => {
              const newFeaturedWindow =
                !state.featuredWindow?.dateEnd && !dateString
                  ? null
                  : {
                      ...state.featuredWindow,
                      dateStart: dateString || null,
                    }

              dispatch({
                featuredWindow: newFeaturedWindow,
              })
            }}
          />
        </Attr>
        <Attr name="End date">
          <DatePicker
            size="large"
            placeholder={`End date`}
            disabledDate={current => current && current <= dayjs().startOf('day')}
            defaultValue={
              state.featuredWindow?.dateEnd ? dayjs(state.featuredWindow.dateEnd) : null
            }
            onChange={(date, dateString) => {
              const newFeaturedWindow =
                !state.featuredWindow?.dateStart && !dateString
                  ? null
                  : {
                      ...state.featuredWindow,
                      dateEnd: dateString || null,
                    }

              dispatch({
                featuredWindow: newFeaturedWindow,
              })
            }}
          />
        </Attr>
        <Attr name="Position">
          <PositionInput
            value={state.featuredWindowPosition}
            addonAfter="order to be shown"
            type="number"
            min={0}
            onChange={e => dispatch({ featuredWindowPosition: +e.target.value })}
          />
        </Attr>
        <Attr name="Featured on Homepage">
          <Checkbox
            checked={state.featuredOnHomepage}
            onChange={e => dispatch({ featuredOnHomepage: e.target.checked })}
          >
            Show on homepage
          </Checkbox>
        </Attr>
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <>
      <Attr name="Start Date">
        <div>{program.featuredWindow?.dateStart}</div>
      </Attr>
      <Attr name="End Date">
        <div>{program.featuredWindow?.dateEnd}</div>
      </Attr>
      <Attr name="Position">
        <div>{program.featuredWindowPosition}</div>
      </Attr>
      <Attr name="Featured on Homepage">
        <div>{program.featuredOnHomepage ? 'Yes' : 'No'}</div>
      </Attr>
    </>
  )
}

const component = { step: Step, Viewer, complete: () => true, applicable: () => true }

export default component
