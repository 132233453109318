import { Select } from 'antd'
import { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAllClubs } from '../../../../actions/admin'
import { useStatus } from '../../../../reducers'
import { actions as acts } from '../../../../constants'

export const Filters = ({ onChange }) => {
  const dispatch = useDispatch()
  const [clubs, setClubs] = useState([])
  const [selectedValue, setSelectedValue] = useState('all')
  const clubsStatus = useStatus(acts.FETCH_ALL_CLUBS)

  useEffect(() => {
    dispatch(fetchAllClubs()).then(fetchedClubs => {
      setClubs(fetchedClubs || [])
    })
  }, [dispatch])

  useEffect(() => {
    if (onChange) {
      onChange(selectedValue)
    }
  }, [selectedValue, onChange])

  const handleFilterChange = value => {
    setSelectedValue(value)
  }

  const options = useMemo(() => {
    const clubOptions = clubs
      .map(club => ({
        value: club.sid,
        label: club.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    return [
      {
        value: 'all',
        label: 'All (Play page)',
      },
      {
        value: 'homepage',
        label: 'Homepage',
      },
      ...clubOptions,
    ]
  }, [clubs])

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <span style={{ marginRight: '4px' }}>Filter:</span>
      <Select
        style={{ minWidth: '300px' }}
        showSearch
        optionFilterProp="label"
        value={selectedValue}
        onChange={handleFilterChange}
        options={options}
        loading={clubsStatus.pending}
        disabled={clubsStatus.pending}
      />
    </div>
  )
}
