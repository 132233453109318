import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { actions as acts } from '../../../../constants'
import { useStatus, useStatusMsg } from '../../../../reducers'
import { clearStatus } from '../../../../actions/status'
import { updateBtlFeaturedHomepageClub } from '../../../../actions/admin'
import FeaturedHomepageClubForm from './FeaturedHomepageClubForm'

const EditFeaturedHomepageClubModal = ({ complete, initialFeaturedHomepageClub }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [featuredHomepageClub, setFeaturedHomepageClub] = useState(initialFeaturedHomepageClub)
  const status = {
    update: useStatus(acts.UPDATE_BTL_FEATURED_HOMEPAGE_CLUB),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_BTL_FEATURED_HOMEPAGE_CLUB))
    }
  }, [dispatch])

  useStatusMsg(status.update, { error: 'Failed to update featured homepage club' })

  const onOk = async () => {
    dispatch(updateBtlFeaturedHomepageClub(featuredHomepageClub))
      .then(res => completer(res))
      .catch(() => {})
  }

  const formComplete =
    featuredHomepageClub &&
    featuredHomepageClub.state &&
    featuredHomepageClub.city &&
    featuredHomepageClub.club &&
    featuredHomepageClub.lat &&
    featuredHomepageClub.lng

  return (
    <Modal
      title={'Edit Featured Homepage Club'}
      visible
      okText={'Save'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.update.pending || false}
    >
      <FeaturedHomepageClubForm
        featuredHomepageClub={featuredHomepageClub}
        onChange={updatedFeaturedHomepageClub =>
          setFeaturedHomepageClub(updatedFeaturedHomepageClub)
        }
      />
    </Modal>
  )
}

export default EditFeaturedHomepageClubModal
