import { DatePicker, Form, Switch } from 'antd'
import { Label } from '../../../../components/common'
import dayjs from 'dayjs'

const FeaturedProgramForm = ({ onChange, featuredProgram }) => {
  const handleChange = newValues => {
    if (typeof onChange === 'function') {
      onChange({ ...featuredProgram, ...newValues })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item
        required={true}
        name="featuredWindow.dateStart"
        label={<Label>Featured window start date</Label>}
        initialValue={
          featuredProgram?.featuredWindow?.dateStart
            ? dayjs(featuredProgram.featuredWindow.dateStart)
            : null
        }
      >
        <DatePicker
          size="large"
          placeholder="Start date"
          onChange={(date, dateString) => {
            const newFeaturedWindow =
              !featuredProgram?.featuredWindow?.dateEnd && !dateString
                ? null
                : {
                    ...featuredProgram?.featuredWindow,
                    dateStart: dateString || null,
                  }

            handleChange({
              featuredWindow: newFeaturedWindow,
            })
          }}
        />
      </Form.Item>

      <Form.Item
        required={true}
        name="featuredWindow.dateEnd"
        label={<Label>Featured window end date</Label>}
        initialValue={
          featuredProgram?.featuredWindow?.dateEnd
            ? dayjs(featuredProgram.featuredWindow.dateEnd)
            : null
        }
      >
        <DatePicker
          size="large"
          placeholder="End date"
          onChange={(date, dateString) => {
            const newFeaturedWindow =
              !featuredProgram?.featuredWindow?.dateStart && !dateString
                ? null
                : {
                    ...featuredProgram?.featuredWindow,
                    dateEnd: dateString || null,
                  }

            handleChange({
              featuredWindow: newFeaturedWindow,
            })
          }}
        />
      </Form.Item>

      <Form.Item
        required={true}
        name="featuredOnHomepage"
        label={<Label>Featured on homepage</Label>}
        initialValue={featuredProgram?.featuredOnHomepage}
      >
        <Switch
          size="large"
          checked={featuredProgram?.featuredOnHomepage}
          onChange={checked => {
            handleChange({
              featuredOnHomepage: checked,
            })
          }}
        />
      </Form.Item>
    </Form>
  )
}

export default FeaturedProgramForm
