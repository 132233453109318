import React, { useState, useEffect, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { paths, features } from '../constants'
import { programTypes, statuses } from '../constants/program'
import { Noner } from '../components/common'
import { isBookable } from '../util/clinic'
import { LoadingOutlined } from '@ant-design/icons'
import { isBooked, dateTimeSummary } from '../util/program'
import { fetchProgramAttributes } from '../actions/partner'
import { searchAllPrograms } from '../actions/org'
import { fetchProgramsSearch } from '../actions/admin'
import Tag from './Tag'
import { TagList } from './tag.jsx'
import Address from './Address'
import get from 'lodash.get'
import { Select, Tooltip } from 'antd'
import { DebounceSearch, DebounceSelect } from './select-search'

const { Option } = Select

// program can be a program object or a program id
export const AdminProgramLink = ({ children, program, ...rest }) => {
  let sig = null
  let childs = children

  if (program) {
    sig = typeof program === 'string' ? program : program.id
  }

  if (!sig) {
    return childs
  }

  // render something if children is not passed
  if (!childs) {
    childs = typeof program === 'string' ? program : program.name
  }

  return (
    <Link to={paths.admin.PROGRAM(sig)} {...rest}>
      {childs}
    </Link>
  )
}

const VSpace = styled.div`
  height: 0.2em;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.font.color.primary};
  border-radius: ${props => props.theme.br[2]};
  padding-left: ${props => props.theme.spacing[3]};
  padding-right: ${props => props.theme.spacing[3]};
  padding-top: ${props => props.theme.spacing[3]};
  padding-bottom: ${props => props.theme.spacing[3]};
  margin-right: ${props => props.theme.spacing[3]};
  margin-bottom: ${props => props.theme.spacing[3]};
  width: ${props => props.theme.width[6]};

  &:hover {
    color: ${props => props.theme.font.color.primary};
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: ${props => props.theme.font.size[8]};
  font-weight: ${props => props.theme.font.weight[5]};
  color: ${props => props.theme.font.color.secondary};
`

const Title = styled.div`
  font-size: ${props => props.theme.font.size[5]};
  font-weight: ${props => props.theme.font.weight[5]};
`

const ReservationContainer = styled.div`
  display: flex;
  justify-content: start;
  color: ${props => props.theme.font.color.secondary};
  font-size: ${props => props.theme.font.size[8]};
`

const Header = ({ program }) => {
  const dateDesc = dateTimeSummary(program)
  return (
    <HeaderContainer>
      <div title={dateDesc}>{dateDesc}</div>
      {Array.isArray(program.waitlist) && program.waitlist.length > 0 && (
        <div>Waitlist {program.waitlist.length}</div>
      )}
      <div>
        {Array.isArray(program.bookings) && (
          <div>
            {program.bookings.filter(isBooked).length} / {program.maxParticipants} booked
          </div>
        )}
      </div>
    </HeaderContainer>
  )
}

const Host = ({ program }) => {
  const theme = useContext(ThemeContext)
  if (!program.host) {
    return null
  }
  return (
    <div style={{ color: theme.font.color.secondary, fontSize: theme.font.size[7] }}>
      Hosted by {program.host.name}
    </div>
  )
}

const Loc = ({ program }) => {
  const theme = useContext(ThemeContext)
  const loc = get(program, 'location.address')
  if (!loc) {
    return null
  }
  return (
    <div style={{ color: theme.font.color.secondary, fontSize: theme.font.size[7] }}>
      <div>{loc.name}</div>
      <Address.SummaryLine address={loc} />
    </div>
  )
}

const Reservations = ({ program }) => {
  const reservations = program.resourceReservations

  if (!reservations || reservations.length === 0) {
    return null
  }
  const venues = program.resourceReservations.map(reservation => ({
    ...reservation.resource.venue,
    resourceName: reservation.resource.name,
  }))

  const tooltipText = venue => (
    <>
      <div style={{ fontWeight: 'bold' }}>{venue.resourceName}</div>
      <div>{venue.name}</div>
      <div>{venue.address.line1}</div>
      <div>{`${venue.address.city}, ${venue.address.state}`}</div>
    </>
  )

  return (
    <ReservationContainer>
      {venues.map((venue, i) => (
        <Tooltip title={tooltipText(venue)}>
          <span style={{ marginRight: '0.25em' }}>
            {i < venues.length - 1 ? `${venue.name},` : `${venue.name}`}
          </span>
        </Tooltip>
      ))}
    </ReservationContainer>
  )
}

const Tags = ({ program }) => {
  const tags = get(program, 'tags')
  if (!Array.isArray(tags) || tags.length === 0) {
    return null
  }
  return (
    <div>
      <TagList tags={tags.map(t => t.tag)} />
    </div>
  )
}

export const Type = ({ type }) => {
  const t = programTypes[type]
  if (!t) {
    return (
      <Tag>
        <Noner none="Unknown type" />
      </Tag>
    )
  }
  return <Tag>{t.label}</Tag>
}

export const Status = ({ status }) => {
  const s = statuses[status]
  if (!s) {
    return (
      <Tag>
        <Noner none="Unknown status" />
      </Tag>
    )
  }
  return (
    <Tooltip title="Status">
      <Tag>{s.label}</Tag>
    </Tooltip>
  )
}

const Action = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.09em;
  font-weight: bold;
  font-size: 12px;
`

export const InfoTags = ({ program, style }) => {
  const tags = []
  if (isBookable(program)) {
    tags.push(
      <Tag style={style} key="live" title="This event is publicly bookable">
        Bookable
      </Tag>,
    )
  }
  if (program.status) {
    tags.push(<Status status={program.status} />)
  }
  if (program.hidden) {
    tags.push(
      <Tag style={style} key="hidden">
        Hidden
      </Tag>,
    )
  }
  if (program.draft) {
    tags.push(
      <Tag style={style} key="draft">
        Draft
      </Tag>,
    )
  }

  return <div>{tags}</div>
}

const AttrsLine = ({ program }) => {
  const attributes = useContext(AttributesContext)

  if (!program.hasOwnProperty('attributes') && !Array.isArray(program.attributes)) {
    return null
  }

  const groupedAttributes = {}
  program.attributes.forEach(obj => {
    if (groupedAttributes[obj.attribute]) {
      groupedAttributes[obj.attribute].push(obj.value)
    } else {
      groupedAttributes[obj.attribute] = [obj.value]
    }
  })

  const attrElems = Object.keys(groupedAttributes).map(key => {
    const attributeName = attributes[key].name
    const valueNames = groupedAttributes[key].map(valueId => attributes[key].values[valueId].name)
    return (
      <Tag key={key}>
        <strong>{attributeName}:</strong> {valueNames.join(', ')}
      </Tag>
    )
  })

  return <div>{attrElems}</div>
}

export const ProgramCard = ({ osid, program }) => {
  const theme = useContext(ThemeContext)
  // sometimes users of program cards know the oside of an org, so prefer that to orgId
  // TODO remove reference to org_id once we remove sequelize
  let orgId
  if (program.org_id) {
    orgId = program.org_id
  } else {
    orgId = program.org && typeof program.org === 'object' ? program.org.sid : program.org
  }
  return (
    <Card key={program.id} style={{ color: program.draft ? theme.font.color.secondary : null }}>
      <Link
        to={paths.org.PROGRAM(osid ? osid : orgId, program.id)}
        style={{ color: 'inherit', width: '100%', marginBottom: theme.spacing[3] }}
      >
        {program.draft ? (
          <center>
            <i style={{ fontSize: '11px' }}>Draft</i>
          </center>
        ) : null}
        <Header program={program} />
        <Title>{program.name}</Title>
        <Loc program={program} />
        <Reservations program={program} />
        <Host program={program} />
        <InfoTags program={program} />
        <VSpace />
        <AttrsLine program={program} />
        <VSpace />
        <Tags program={program} />
      </Link>
      <div style={{ marginTop: 'auto', display: 'flex', alignItems: 'flex-start' }}>
        <Action style={{ marginRight: theme.spacing[3] }}>
          <a href={paths.org.BOOKINGS(osid, program.id)}>Bookings</a>
        </Action>
        <Action style={{ marginRight: theme.spacing[3] }}>
          <a href={paths.org.RESERVATIONS(osid, program.id)}>Reservations</a>
        </Action>
        <Action>
          <a href={paths.org.DUPLICATE(osid, program.id)}>Duplicate</a>
        </Action>
      </div>
    </Card>
  )
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const AttributesContext = React.createContext()

const attributeMapper = attributes => {
  const attributesMap = {}

  attributes.forEach(attr => {
    const valuesMap = {}

    attr.values.forEach(value => {
      valuesMap[value.id] = value
    })

    attr.values = valuesMap
    attributesMap[attr.id] = attr
  })
  return attributesMap
}

export const ProgramList = ({ programs, isLoading }) => {
  const { osid } = useParams()
  const [programAttributes, setProgramAttributes] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProgramAttributes(osid)).then(attributes => {
      setProgramAttributes(attributeMapper(attributes))
    })
  }, [])

  if (isLoading) {
    return <i>Loading...</i>
  } else if (!programs || programs.length === 0) {
    return <i>No {features.program.name.plural}</i>
  }

  if (!programAttributes) {
    return <LoadingOutlined />
  }

  const elems = programs.map(p => <ProgramCard key={p.id} osid={osid} program={p} />)

  return (
    <AttributesContext.Provider value={programAttributes}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>{elems}</div>
    </AttributesContext.Provider>
  )
}

export const ProgramSearch = ({ onResult, ...props }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()

  const handleChange = result => {
    if (typeof onResult === 'function') {
      onResult(result.data)
    }
  }

  return (
    <DebounceSearch
      onResult={result => handleChange(result)}
      searcher={e =>
        dispatch(
          searchAllPrograms({
            osid,
            sort: { column: 'date', order: 'asc' },
            searchInput: e.target.value,
          }),
        )
      }
      {...props}
    />
  )
}

export const ProgramSelectOption = ({ program }) => {
  const theme = useContext(ThemeContext)
  return (
    <div
      key={program.id}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing[2],
        padding: `${theme.spacing[1]} ${theme.spacing[2]}`,
        borderRadius: theme.br[2],
        border: '1px solid hsl(220,12%,95%)',
      }}
    >
      <div>
        {program.name}
        <div style={{ fontSize: '12px', color: theme.font.color.secondary }}>
          <span>{program.date ? dateTimeSummary(program) : null}</span>
          <span> {program.hidden ? <Tag>Hidden</Tag> : null}</span>
          <span> {program.draft ? <Tag>Draft</Tag> : null}</span>
          <div>
            <div>{program.location ? program.location.address.name : null}</div>
            <div>
              {program.location ? <Address.SummaryLine address={program.location.address} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProgramSelect = ({ value, onChange, searchAcrossOrgs = false, ...props }) => {
  const dispatch = useDispatch()
  const [val] = useState(value)
  const [programs, setPrograms] = useState({})
  const { osid } = useParams()

  const handleChange = ({ value }) => {
    if (typeof onChange === 'function') {
      const program = programs[value]
      onChange(program)
    }
  }

  const mapPrograms = programs =>
    programs.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {})

  const handleResult = result => {
    const programMap = mapPrograms(result.data)
    setPrograms(programMap)
  }

  const renderOptions = result => {
    const programMap = mapPrograms(result.data)
    return Object.values(programMap).map(p => (
      <Option key={p.id} value={p.id}>
        <ProgramSelectOption program={p} />
      </Option>
    ))
  }

  const searcher = searchInput => {
    // TODO: We are hard capping at 100 items so we don't break the application
    // Ideally we have an infinite scroll fetching mechanism
    const limit = 100

    if (osid && !searchAcrossOrgs) {
      return dispatch(
        searchAllPrograms({
          osid,
          sort: { column: 'date', order: 'asc' },
          limit,
          searchInput,
        }),
      )
    }
    return dispatch(
      fetchProgramsSearch({ sort: { column: 'date', order: 'asc' }, limit, searchInput }),
    )
  }

  return (
    <DebounceSelect
      showSearch
      value={val}
      placeholder="Select a program"
      searcher={searchInput => searcher(searchInput)}
      onChange={handleChange}
      onResult={handleResult}
      renderOptions={renderOptions}
      style={{ width: '100%' }}
      {...props}
    />
  )
}
