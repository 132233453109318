import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Form, Input } from 'antd'
import StateSelector from '../../../../components/StateSelector'
import ClubSelect from '../../../../components/ClubSelect'
import { Label } from '../../../../components/common'

const initState = {
  club: null,
  state: null,
  city: null,
  lat: null,
  lng: null,
}

const FeaturedHomepageClubForm = ({ onChange, featuredHomepageClub }) => {
  const theme = useContext(ThemeContext)

  if (!featuredHomepageClub) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const { state, city, lat, lng } = featuredHomepageClub

  const handleChange = newValues => {
    if (typeof onChange === 'function') {
      onChange({ ...featuredHomepageClub, ...newValues })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item
        required={true}
        name="club"
        label={<Label>Club</Label>}
        initialValue={featuredHomepageClub.club}
      >
        <ClubSelect
          placeholder="Select a club"
          style={{ width: '100%' }}
          value={featuredHomepageClub.club}
          onChange={club => handleChange({ club })}
        />
      </Form.Item>

      <Form.Item required={true} name="state" label={<Label>State</Label>} initialValue={state}>
        <StateSelector
          id="state"
          onChange={v => handleChange({ state: v })}
          value={state}
          style={{ width: theme.width[5] }}
        />
      </Form.Item>

      <Form.Item name="city" required={true} label={<Label>City</Label>} initialValue={city}>
        <Input
          placeholder=""
          onChange={e => handleChange({ city: e.target.value })}
          style={{ width: theme.width[5] }}
          value={city}
        />
      </Form.Item>

      <Form.Item name="lat" required={true} label={<Label>Latitude</Label>} initialValue={lat}>
        <Input
          placeholder=""
          onChange={e => handleChange({ lat: e.target.value })}
          style={{ width: theme.width[5] }}
          value={lat}
        />
      </Form.Item>

      <Form.Item name="lng" required={true} label={<Label>Longitude</Label>} initialValue={lng}>
        <Input
          placeholder=""
          onChange={e => handleChange({ lng: e.target.value })}
          style={{ width: theme.width[5] }}
          value={lng}
        />
      </Form.Item>
    </Form>
  )
}

export default FeaturedHomepageClubForm
