import { Card, Button, Dropdown } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { US_STATES } from '../../../../constants'
import ImagePreview from '../../../../components/ImagePreview'
import ActionsMenu from './ActionsMenu'

const ClubCard = ({ featuredHomepageClub, onEdit, onDelete, isReordering }) => {
  const { club, state, city, lat, lng } = featuredHomepageClub
  const stateName = US_STATES[state]

  return (
    <Card
      style={{
        width: '320px',
        position: 'relative',
      }}
    >
      {!isReordering && (
        <Dropdown
          overlay={
            <ActionsMenu
              featuredHomepageClub={featuredHomepageClub}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          }
          trigger={['click']}
        >
          <Button
            type="text"
            icon={<EllipsisOutlined />}
            style={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              padding: '4px 8px',
              height: 'auto',
              zIndex: 1,
            }}
          />
        </Dropdown>
      )}
      <div style={{ display: 'flex', gap: '16px', alignItems: 'flex-start' }}>
        <div style={{ width: '80px', flexShrink: 0 }}>
          {club.cover ? (
            <ImagePreview
              asset={club.cover}
              width="80px"
              height="80px"
              style={{ objectFit: 'cover', borderRadius: '4px' }}
            />
          ) : (
            <div
              style={{
                width: '80px',
                height: '80px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#999',
              }}
            >
              No Image
            </div>
          )}
        </div>
        <div style={{ flex: 1, minWidth: 0 }}>
          <div
            style={{
              fontSize: '16px',
              fontWeight: 500,
              marginBottom: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: 'calc(100% - 20px)',
            }}
          >
            {club.name}
          </div>
          <div style={{ color: '#666', fontSize: '14px' }}>
            <div>
              <span style={{ fontWeight: 500, marginRight: '8px' }}>State:</span>
              <span>{stateName}</span>
            </div>
            {city && (
              <div>
                <span style={{ fontWeight: 500, marginRight: '8px' }}>City:</span>
                <span>{city}</span>
              </div>
            )}
            {lat && (
              <div>
                <span style={{ fontWeight: 500, marginRight: '8px' }}>Latitude:</span>
                <span>{lat}</span>
              </div>
            )}
            {lng && (
              <div>
                <span style={{ fontWeight: 500, marginRight: '8px' }}>Longitude:</span>
                <span>{lng}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ClubCard
