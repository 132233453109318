import { useState, useEffect } from 'react'
import { Menu, Typography, Dropdown, Alert } from 'antd'
import { LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { Head, Button, Cta, Title } from '../../../../components/common'

import FeaturedProgramModal from './FeaturedProgramModal'
import FeaturedProgramCard from './FeaturedProgramCard'
import DraggableFeaturedProgramList from './DraggableFeaturedProgramList'
import OrderBadge from './OrderBadge'
import { Filters } from './Filters'
import { useFeaturedProgramsFilter } from './useFeaturedProgramsFilter'

const { Paragraph } = Typography

const FeaturedPrograms = () => {
  const {
    filterValue,
    filteredPrograms,
    allPrograms,
    isLoading,
    isReordering,
    handleFilterChange,
    refetchFeaturedPrograms,
    reorderPrograms,
  } = useFeaturedProgramsFilter()

  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)
  const [reordering, setReordering] = useState(false)
  const [newOrder, setNewOrder] = useState([])

  // Reset reordering state when filter value changes
  useEffect(() => {
    if (reordering) {
      setReordering(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  const handleActionMenu = e => {
    if (e.key === 'add') {
      setAdding(true)
    } else if (e.key === 'reorder') {
      setNewOrder(filteredPrograms)
      setReordering(true)
    }
  }

  const actionMenu = (
    <Menu
      onClick={handleActionMenu}
      items={[
        { key: 'add', label: 'Add featured program' },
        { key: 'reorder', label: 'Reorder' },
      ]}
    />
  )

  /*
   * When reordering filtered programs, we need to maintain the positions of non-filtered items
   * while applying the new order to the filtered ones. To achieve this:
   *
   * We create a map of where each program should move to. For instance, if program A
   * should take B's position, we map A -> B. Then we go through each filtered program
   * and place its target program in its original position.
   *
   * Example:
   * allPrograms:      [A, E, B, C, D, F, G, H]
   * filteredPrograms: [E, D, G]
   * newOrder:         [G, E, D]
   *
   * After reordering:
   * - E is in position 2, should be replaced by G
   * - D is in position 5, should be replaced by E
   * - G is in position 7, should be replaced by D
   * Final: [A, G, B, C, E, F, D, H]
   *
   * The swap map would contain:
   * E -> G (E's position should contain G)
   * D -> E (D's position should contain E)
   * G -> D (G's position should contain D)
   */
  const onSaveNewOrder = () => {
    const finalOrder = [...allPrograms]

    // Create a map of current program to its target program
    const swapMap = new Map()
    filteredPrograms.forEach((program, i) => {
      swapMap.set(program.id, newOrder[i].id)
    })

    // Apply swaps
    filteredPrograms.forEach(program => {
      const itemIdx = allPrograms.findIndex(p => p.id === program.id)
      const targetId = swapMap.get(program.id)
      const target = allPrograms.find(p => p.id === targetId)

      finalOrder[itemIdx] = target
    })

    reorderPrograms(finalOrder.map(p => p.id)).then(() => {
      refetchFeaturedPrograms()
      setReordering(false)
    })
  }

  const renderProgramList = programs => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '32px',
        position: 'relative',
        justifyContent: 'flex-start',
      }}
    >
      {programs.map((program, index) => (
        <div
          key={program.id}
          style={{
            width: '320px',
            position: 'relative',
          }}
        >
          <OrderBadge order={index + 1} />
          <FeaturedProgramCard
            featuredProgram={program}
            onEdit={() => setEditing(program)}
            onDelete={refetchFeaturedPrograms}
          />
        </div>
      ))}
    </div>
  )

  return (
    <>
      <Head style={{ gap: '24px' }}>
        <div>
          <Title>Featured homepage programs</Title>
          <Paragraph>Manage the featured programs</Paragraph>
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: 'flex' }}>
          {reordering ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={() => setReordering(false)} style={{ marginRight: '.5em' }}>
                Cancel
              </Button>
              <Cta onClick={onSaveNewOrder} disabled={isReordering}>
                Save
              </Cta>
            </div>
          ) : (
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Button style={{ minWidth: '100px' }} disabled={isLoading}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      </Head>
      <Alert
        style={{ marginBottom: '32px' }}
        description={
          <div>
            The order of the programs is <b>global!</b> We don't have a separate order for each
            filter.
            <div>
              That means that swapping the order of two programs for a specific filter, e.g.,
              homepage, will also affect the order of the reordered programs throughout the app
              (Play page, Clubs page, etc...)
            </div>
          </div>
        }
        type="info"
        closable
        showIcon
      />
      <div style={{ marginBottom: '32px' }}>
        <Filters onChange={handleFilterChange} />
      </div>
      {isLoading && <LoadingOutlined />}
      {!isLoading &&
        (reordering ? (
          <DraggableFeaturedProgramList
            items={newOrder}
            onReorder={async order => {
              setNewOrder(order)
              await new Promise(resolve => setTimeout(resolve, 1000))

              // setTimeout(() => {
              //   flushSync(() => setNewOrder(order))
              // }, 1000)
            }}
          />
        ) : (
          renderProgramList(filteredPrograms)
        ))}
      {adding && (
        <FeaturedProgramModal
          complete={() => {
            refetchFeaturedPrograms()
            setAdding(false)
          }}
        />
      )}
      {editing && (
        <FeaturedProgramModal
          featuredProgram={editing}
          complete={() => {
            refetchFeaturedPrograms()
            setEditing(null)
          }}
        />
      )}
    </>
  )
}

export default FeaturedPrograms
