import ExploreClubs from './ExploreClubs'
import FeaturedGeos from './FeaturedGeos'
import FeaturedFacilities from './FeaturedFacilities'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import { Tabs } from 'antd'
import FeaturedHomepageClubs from './FeaturedHomepageClubs/FeaturedHomepageClubs'
import FeaturedPrograms from './FeaturedPrograms/FeaturedPrograms'

const items = [
  {
    key: '1',
    label: 'Explore Clubs',
    children: <ExploreClubs />,
  },
  {
    key: '2',
    label: 'Featured Geos',
    children: <FeaturedGeos />,
  },
  {
    key: '3',
    label: 'Featured Facilities',
    children: <FeaturedFacilities />,
  },
  {
    key: '4',
    label: 'Featured Homepage Clubs',
    children: <FeaturedHomepageClubs />,
  },
  {
    key: '5',
    label: 'Featured  Programs',
    children: <FeaturedPrograms />,
  },
]

const BTLBrandSettings = () => {
  return (
    <>
      <PageHeading>
        <PageTitle>Break the Love brand settings</PageTitle>
      </PageHeading>
      <Tabs
        items={items}
        tabBarStyle={{
          maxWidth: 'fit-content',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        }}
      />
    </>
  )
}

export default BTLBrandSettings
