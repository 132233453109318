import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../../../reducers'
import { actions as acts } from '../../../../constants'
import {
  fetchFeaturedPrograms,
  fetchClubFeaturedPrograms,
  reorderFeaturedPrograms,
} from '../../../../actions/admin'

export const useFeaturedProgramsFilter = () => {
  const dispatch = useDispatch()
  const [allPrograms, setAllPrograms] = useState([])
  const [filteredPrograms, setFilteredPrograms] = useState([])
  const [filterValue, setFilterValue] = useState('all')

  const status = {
    fetch: useStatus(acts.FETCH_FEATURED_PROGRAMS),
    fetchClub: useStatus(acts.FETCH_CLUB_FEATURED_PROGRAMS),
    reorder: useStatus(acts.REORDER_FEATURED_PROGRAMS),
  }

  const isLoading = status.fetch.pending || status.fetchClub.pending
  const isReordering = status.reorder.pending

  useStatusMsg(status.fetch, { error: 'Failed to fetch featured programs' })
  useStatusMsg(status.fetchClub, { error: 'Failed to fetch club featured programs' })
  useStatusMsg(status.reorder, { error: 'Failed to reorder featured programs' })

  const fetchDataForFilter = useCallback(
    filterValue => {
      setFilterValue(filterValue)

      if (filterValue === 'all' || filterValue === 'homepage') {
        dispatch(fetchFeaturedPrograms()).then(fetchedPrograms => {
          setAllPrograms(fetchedPrograms || [])
          setFilteredPrograms(
            fetchedPrograms?.filter(p =>
              filterValue === 'homepage' ? p.featuredOnHomepage : true,
            ),
          )
        })
      } else {
        dispatch(fetchClubFeaturedPrograms({ clubSid: filterValue })).then(fetchedPrograms => {
          setFilteredPrograms(fetchedPrograms || [])
          // Fetch all programs to maintain the complete list
          dispatch(fetchFeaturedPrograms()).then(allFetchedPrograms => {
            setAllPrograms(allFetchedPrograms || [])
          })
        })
      }
    },
    [dispatch],
  )

  // Initial fetch
  useEffect(() => {
    fetchDataForFilter('all')
  }, [fetchDataForFilter])

  const handleFilterChange = useCallback(
    selectedFilterValue => {
      fetchDataForFilter(selectedFilterValue)
    },
    [fetchDataForFilter],
  )

  const refetchFeaturedPrograms = useCallback(() => {
    fetchDataForFilter(filterValue)
  }, [filterValue, fetchDataForFilter])

  const reorderPrograms = useCallback(
    programIds => {
      return dispatch(reorderFeaturedPrograms({ programIds }))
    },
    [dispatch],
  )

  return {
    filterValue,
    filteredPrograms,
    allPrograms,
    isLoading,
    isReordering,
    handleFilterChange,
    refetchFeaturedPrograms,
    reorderPrograms,
  }
}
