import { useEffect } from 'react'
import { Menu, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { actions as acts } from '../../../../constants'
import { useStatus, useStatusMsg } from '../../../../reducers'
import { clearStatus } from '../../../../actions/status'
import { updateProgram } from '../../../../actions/admin'

const ActionsMenu = ({ featuredProgram, onDelete, onEdit }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.UPDATE_FEATURED_PROGRAM)

  useStatusMsg(deleteStatus, {
    pending: 'Unfeaturing program...',
    error: 'Failed to unfeature program',
    success: 'Program unfeatured',
  })

  useEffect(() => () => dispatch(clearStatus(acts.UPDATE_FEATURED_PROGRAM)), [dispatch])

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      onEdit(featuredProgram)
    } else if (e.key === 'delete') {
      Modal.confirm({
        title: 'Unfeature program?',
        okText: 'Unfeature',
        okButtonProps: {
          danger: true,
          loading: deleteStatus.pending || false,
          disabled: deleteStatus.pending || false,
        },
        content: <p>Are you sure you want to unfeature this program?</p>,
        onOk: () => {
          dispatch(
            updateProgram(featuredProgram.id, { featuredOnHomepage: false, featuredWindow: null }),
          ).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(featuredProgram)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete" danger>
        Unfeature
      </Menu.Item>
    </Menu>
  )
}

export default ActionsMenu
